@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&family=Chivo&family=Assistant:wght@500&display=swap');

html body {
  margin: 2.6vw auto;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #ccc;
}

main {
  letter-spacing: 0.32px;
  line-height: 28.8px;
  color: #e0e0e0;
}