.header{
    padding-right: 5vw;
    padding-left: 5vw;
    position: fixed;
    width:100%;
    z-index: 1;
}

.header-space {
    height: 62.5px;
}

.header .header-left {
    display: flex;
    align-items: center;
}

.header .logo{
    width: 50px;
    margin-right:20px;
}
.header .my-navbar{
    padding-top: 0px;
    padding-bottom: 0px;
}
.header .title{
    font-family: Chivo;
    font-weight: 800;
    font-style: normal;
    font-size: 13px;
    line-height: 2em;
    letter-spacing: .03em;
    text-transform: none;
    color: #aaa;
}

.header .my-navbar .container-fluid{
    padding:0px;
}

@media only screen and (max-width: 921px) {
    .header-space {
        height: 121.5px;
    }
    .header .header-left {
        flex-direction: column;
        align-items: flex-start;
    }
    .header .logo{
        width: 80px;
    }
    
    .header .title{
        font-weight: 800;
        font-size: 12px;
    }
    .header .my-navbar .container-fluid{
        padding-top:5px;
    }
  }

.header .offcanvas-body {
    font-family: 'Assistant', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    letter-spacing: .02em;
    text-transform: none;
    line-height: 1em;
}

.header .my-navbar-toggler {
    border: none;
    color: #ccc;
    padding: 0px;
}

.header .my-navbar-toggler .navbar-toggler-icon{
    width: 24px;
    background-image: url("assets/images/burger.svg")
}

.header .my-navbar-toggler.navbar-toggler:focus{
    box-shadow: none;
}

.header .dropdown-menu.show, .my-offcanvas .dropdown-menu.show{
    --bs-dropdown-bg: transparent;
    padding-top:5px;
}

.header .dropdown-item:focus, .header .dropdown-item:hover, 
.my-offcanvas .dropdown-item:focus, .my-offcanvas .dropdown-item:hover{
    background-color: transparent;
}
.header .my-offcanvas a.dropdown-item, .header .my-offcanvas .nav-link{
    font-size: 14px;
    padding-bottom:10px;
    text-align: left;
}

.header .my-offcanvas a.dropdown-item{
    padding-left: 10px;;
}

.header .my-offcavas-body{
    padding-top:10px;
    padding-right:30px;
}

.my-offcanvas a.dropdown-item, .my-offcanvas .nav-link{
    font-size: 36px;
    font-weight: 400;
    font-family: 'Assistant', sans-serif;
    text-align: center;
    padding-top: 0px;
    padding-bottom:20px;
    color: #aaa;
    font-style: normal;
    letter-spacing: .02em;
    text-transform: none;
    line-height: normal;
}

.my-offcanvas .offcanvas-header .btn-close, 
.my-offcanvas .offcanvas-header .btn-close:hover{
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity);
}

.my-offcanvas{
    background-color: #000;
    color: #ccc;
  }
  
.my-offcanvas .btn-close{
    margin-top:35px;
    margin-right:10px;
    --bs-btn-close-bg: url("assets/images/close-btn.svg")
}

.my-offcanvas .offcanvas-body{
    text-align: center;;
}