.gif{
    margin: 6vw auto;
    margin-bottom: 5vw;
    max-width: 1060px;

    display: flex;
}

.gif-left-col, .gif-right-col {
    width: 50%;
    padding:15px;
}

.gif-left-col{
    padding-left: 30px;
}

.gif-right-col{
    padding-right: 30px;
}

.gif .gif-left-col div,
.gif .gif-right-col div{
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    margin-bottom:30px;
}

.gif div.shorter{
    padding-top: 80%; /* 4:5 Aspect Ratio */
}

.gif iframe {
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


@media only screen and (max-width: 860px) {
    .gif{
        margin: 8vw auto;
    }
}

@media only screen and (max-width: 640px) {
    .gif{
        margin: 14vw auto;
        max-width: 620px;
        flex-direction: column;
    }
    .gif-left-col, .gif-right-col {
        width: 100%;
    }

    .gif .gif-left-col div,
    .gif .gif-right-col div{
        position: relative;
        width: 100%;
        padding-top: 100%; /* 1:1 Aspect Ratio */
    }

    .gif-left-col, .gif-right-col{
        padding-left: 20px;
        padding-right: 20px;
    }

    .gif div.shorter{
        padding-top: 80%; /* 4:5 Aspect Ratio */
    }

    .gif iframe {
        position:  absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
